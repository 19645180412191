import React, { useState, useEffect } from 'react';
import { Checkbox as CheckboxContainer } from '@/components/ui/checkbox';
import Label from '@/components/atoms/label';

import { cn } from '@/lib/utils';

function Checkbox({ htmlFor, label, className, wrapClassName, labelClassName, disabled, showHtmlContent, id, checked, onCheckedChange, ...props }) {
	const fieldId = id || htmlFor;
	const [isChecked, setIsChecked] = useState(checked || false);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	useEffect(() => {
		// only fire change when form value and internal state out of sync
		if (checked === isChecked) return;

		onCheckedChange && onCheckedChange(isChecked);
	}, [isChecked]);

	return (
		<div className={cn('flex items-start gap-2', wrapClassName)}>
			<CheckboxContainer
				id={fieldId}
				disabled={disabled}
				className={cn(
					// Note: add a customed .checkbox-btn to change the label colour when disabled
					'checkbox-btn mt-1',
					className
				)}
				checked={isChecked}
				onCheckedChange={(val) => {
					setIsChecked(val);
				}}
				{...props}
			/>

			{showHtmlContent ? (
				<div dangerouslySetInnerHTML={{ __html: label }}></div>
			) : (
				<Label htmlFor={fieldId} className={cn('font-normal', labelClassName)}>
					{label}
				</Label>
			)}
		</div>
	);
}

export default Checkbox;
